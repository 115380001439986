import * as yup from 'yup';
import {DefaultApprovalStatus} from "../../data/enums/ModelEnums";

export type ReqQuery = {
    operatorId?: string;
    locationId?: string;
    showProcessed?: string
    status?: DefaultApprovalStatus
    skip?: number;
    limit?: number;
};

export type ResData = any

export const validationSchema = yup.object({
    locationId: yup.string().optional(),
    operatorId: yup.string().optional(),
    showProcessed: yup.string().optional(),
    status: yup.mixed<DefaultApprovalStatus>().optional().oneOf(Object.values(DefaultApprovalStatus)),
    skip: yup.number().optional().min(0).default(0),
    limit: yup.number().optional().min(1).max(1000).default(100),
});
