import {
    Button,
    Fab,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    useMediaQuery,
    useTheme
} from '@mui/material';

import {useNavigate} from 'react-router-dom';
import {Options} from "../../event-log/components/table/ListPageService";
import React, {PropsWithChildren, useCallback, useEffect, useState} from 'react';
import {MorphPhotoService} from "../services/MorphPhotoService";
import {useOperatorId} from "../../../hooks/useOperatorId";
import {HumanDate} from "../../mixin/HumanDate";
import {ReviewsList} from '@electrifly/central-client-api';
import {RouterLink} from "../../../elements/RouterLink";
import CheckIcon from '@mui/icons-material/Check';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ConfirmationDialog from "../../mixin/ConfirmationDialog";
import {DefaultApprovalStatus} from "@electrifly/central-client-api/data/enums/ModelEnums";
import {useIsMobile} from "../../../misc/Helpers";

interface MorphPhotoItemProps {
    photo: any
    locationId?: string
    isMobile: boolean
}

function MorphPhotoItem({photo, locationId, isMobile}: MorphPhotoItemProps) {
    const navigate = useNavigate();

    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const operatorId = useOperatorId()
    const togglePhotoStatus = MorphPhotoService.useStore(store => store.toggleStatus);

    const toggleStatus = (status: DefaultApprovalStatus) => togglePhotoStatus(photo._id, status)

    const onDialogClose = (val: boolean) => {
        setShowConfirmDialog(false)

        if (val) {
            toggleStatus(DefaultApprovalStatus.DELETED)
        }
    }

    const ButtonsBlock = () => (
        <Grid
            container
            direction="column"
            sx={{
                justifyContent: "center",
                alignItems: "flex-start",
            }}
        >
            {
                photo.status != 'approved' &&
                <Button size={"small"} onClick={() => toggleStatus(DefaultApprovalStatus.APPROVED)}
                        variant="contained"
                        color={'success'}>
                    Разрешить
                </Button>
            }

            {
                photo.status != 'hidden' &&
                <Button size={"small"} style={{marginTop: 15}}
                        onClick={() => toggleStatus(DefaultApprovalStatus.HIDDEN)}
                        variant="contained" color={'inherit'}>
                    Скрыть
                </Button>
            }

            {
                photo.status !== 'deleted' &&
                <Button size={"small"} style={{marginTop: 15}}
                        onClick={() => setShowConfirmDialog(true)}
                        variant="contained"
                        color={'error'}>
                    Удалить
                </Button>
            }
        </Grid>
    )

    return (
        <TableRow
            key={photo._id}
            hover={true}
            sx={{'&:last-child td, &:last-child th': {border: 0}, textDecoration: 'none'}}
        >
            <TableCell>
                <HumanDate time={photo.created}/>
                <br/>
                <br/>
                <RouterLink to={`/${operatorId}/locations/${photo.entityId}/photos`}>
                    {photo.entityName}
                </RouterLink>
                <br/>
                <br/>
                <RouterLink to={`/${operatorId}/customers/${photo.account}`}>
                    {photo.accountName}
                </RouterLink>

                <ConfirmationDialog message={"Вы уверены что хотите удалить эту фотографию?"}
                                    onClose={onDialogClose} open={showConfirmDialog}/>
            </TableCell>
            <TableCell>
                <img onClick={() => window.open(photo.photoUrl, "_blank")} height={145} width={200} style={{objectFit: 'cover'}} src={photo.photoUrl}/>
                {isMobile && <Grid
                    container
                    direction="row"
                    spacing={1}
                    sx={{
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        marginTop: 1,
                    }}
                >
                    {
                        photo.status != 'approved' && <Grid item>
                            <Fab variant={"circular"} size={"small"} color={"success"}
                                 onClick={() => toggleStatus(DefaultApprovalStatus.APPROVED)}>
                                <CheckIcon fontSize={"small"}/>
                            </Fab>
                        </Grid>
                    }

                    {
                        photo.status != 'hidden' && <Grid item>
                            <Fab variant={"circular"} size={"small"} color={"inherit"}
                                 onClick={() => toggleStatus(DefaultApprovalStatus.HIDDEN)}>
                                <VisibilityOffIcon fontSize={"small"}/>
                            </Fab>
                        </Grid>
                    }

                    {
                        photo.status != 'deleted' && <Grid item>
                            <Fab variant={"circular"} size={"small"} color={"error"}
                                 onClick={() => setShowConfirmDialog(true)}>
                                <DeleteOutlineIcon fontSize={"small"}/>
                            </Fab>
                        </Grid>
                    }
                </Grid>}
            </TableCell>
            {!isMobile && <TableCell>
                <ButtonsBlock/>
            </TableCell>}

        </TableRow>
    )
}

interface ChargeLocationTableProps {
    locationId?: string
}

function MorphPhotosTable({locationId}: ChargeLocationTableProps) {
    const photos = MorphPhotoService.useStore(store => store.photos);
    const isMobile = useIsMobile()

    // const useLocations = useChargeLocation()

    // console.log(useLocations);

    // console.log(photos);

    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell width={isMobile ? '15%' : '30%'}>Адрес/Клиент</TableCell>
                        <TableCell>Фотография</TableCell>
                        {!isMobile && <TableCell>Действия</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        photos.map((photo, key) => (
                            <MorphPhotoItem isMobile={isMobile} locationId={locationId} photo={photo} key={key}/>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export interface Filter {
    operator?: string;
    holder?: string;
}

interface Props {
    filter?: Filter;
}

function LoadWrapper({children, filter = {}, options = {}}: PropsWithChildren<{}> & ActionEventListTableProps) {
    const store = MorphPhotoService.useStore(store => store);
    const {reset, loadNext, setFilter} = store

    useEffect(() => {
        setFilter(filter)

        loadNext();
        return () => reset();
    }, [filter.status]);

    return <>{children}</>
}

interface ActionEventListTableProps {
    filter?: ReviewsList.ReqQuery
    options?: Partial<Options>;
}

export default function MorphPhotosListTableComponent({filter = {}, options = {}}: ActionEventListTableProps) {
    // const filteredLocationIds = useFilteredLocationIds(filter);
    return <MorphPhotoService.Provider createStore={() => MorphPhotoService.createStore(filter, {})}>
        <LoadWrapper filter={filter}>
            <MorphPhotosTable locationId={filter.locationId}/>
        </LoadWrapper>
    </MorphPhotoService.Provider>
}
