import { Box, Stack, Tab, Tabs } from '@mui/material';

import { Title } from '../../elements/Title';
import {Outlet, useParams } from 'react-router-dom';
import {useRouteMatch} from "../../hooks/useRouteMatch";
import {RouterLink} from "../../elements/RouterLink";
import React from "react";
import {useIsMobile} from "../../misc/Helpers";

export function ReviewsListTopMenu() {
    const { operatorId } = useParams();
    const routeMatch = useRouteMatch([`/${operatorId}/reviews/`, `/${operatorId}/reviews/approved`, `/${operatorId}/reviews/hidden`]);
    const currentTab = routeMatch?.pattern?.path;
    const isMobile = useIsMobile()

    return (
        <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTab} variant="scrollable" scrollButtons={isMobile} allowScrollButtonsMobile>
                <Tab label="Не обработанные" value={`/${operatorId}/reviews/`} component={RouterLink} to="" />
                <Tab label="Опубликованные" value={`/${operatorId}/reviews/approved`} component={RouterLink} to="approved" />
                <Tab label="Скрытые" value={`/${operatorId}/reviews/hidden`} component={RouterLink} to="hidden" />
            </Tabs>
        </Box>
    );
}

export function ChargeLocationReviewsListLayout() {
    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Отзывы</Title>
            </Stack>

            <ReviewsListTopMenu />
            <Outlet />
        </>
    );
}
