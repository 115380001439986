import {EventLog, EventLogType, ReviewsList} from '@electrifly/central-client-api';
import _ from 'lodash';
import {DateTime} from 'luxon';
import {StoreApi} from 'zustand';
import createContext from 'zustand/context';
import {API} from "../../../core/api-client";
import {createWithImmer} from "../../../misc/CreateWithImmer";
import {ColumnKey} from "../../event-log/components/table/TableColumns";
import {DefaultApprovalStatus} from "@electrifly/central-client-api/data/enums/ModelEnums";


export type FilterUIType = 'eventlog' | 'chargepoint' | 'transaction' | 'customer' | 'date';

export type Options = Record<ColumnKey, boolean>;

type Data = {
    photos: EventLog[];
    loading: boolean;
    canLoadMore: boolean;

    filter: ReviewsList.ReqQuery;
};

type Actions = {
    reset: () => void;
    loadNext: () => Promise<void>;
    toggleStatus: (photoId: string, status: DefaultApprovalStatus) => Promise<void>
    setFilter: (filter: ReviewsList.ReqQuery) => void,
};

type Service = Data & Actions;

const DEFAULT_FILTER: ReviewsList.ReqQuery = {
    limit: 100,
};

function createDefaultData(): Data {
    return {
        photos: [],
        loading: false,
        canLoadMore: false,

        filter: DEFAULT_FILTER,
    };
}

const createStore = (filter?: ReviewsList.ReqQuery, options?: Partial<Options> | undefined) => {
    const initialData = {
        ...createDefaultData(),
        filter: {...createDefaultData().filter, ...filter},
    };

    return createWithImmer<Service>((set, get) => {
        // WebsocketClient.events.ACTION_EVENT.on(actionEvent => {
        //     const { events, filter } = get();
        //     const passed = transactionPassFilter(actionEvent, filter);
        //     if (!passed) {
        //         set(draft => {
        //             const index = draft.events.findIndex(item => item._id === actionEvent._id);
        //             if (index !== -1) {
        //                 draft.events.splice(index, 1);
        //             }
        //         });
        //
        //         return;
        //     }
        // });

        function resetData() {
            set({photos: []});
        }

        async function toggleStatus(photoId: string, status: DefaultApprovalStatus) {
            const [error, res] = await API.togglePhotoStatus(photoId, status)

            if (error) {
                console.log(error);
                return
            }

            set({photos: get().photos.filter(e => e._id !== res.data._id)})

            return;
        }

        function setFilter(filter: ReviewsList.ReqQuery) {
            set({filter})
        }

        async function loadNext() {
            if (get().loading) {
                debouncedLoadNext();
                return;
            }

            set({loading: true});

            const {filter, photos} = get();

            const skip = photos.length;
            const [error, res] = await API.photosList({
                skip,
                limit: filter.limit,
                locationId: filter.locationId,
                showProcessed: filter.showProcessed,
                status: filter.status,
            });

            if (error) {
                console.error(error);
                set({loading: false});
                return;
            }

            const newData = res.data;
            const canLoadMore = newData.length === filter.limit;

            set({
                loading: false,
                canLoadMore: canLoadMore,
                photos: [...get().photos, ...newData],
            });
        }

        function loadNexWithReset() {
            resetData();
            loadNext();
        }

        const debouncedLoadNext = _.debounce(() => loadNext(), 300);
        const debouncedLoadNextWithReset = _.debounce(() => loadNexWithReset(), 300);

        return {
            ...initialData,
            reset: () => set({...initialData}),
            loadNext,
            toggleStatus,
            setFilter,
        };
    });
};

const {Provider, useStore} = createContext<StoreApi<Service>>();

export const MorphPhotoService = {
    Provider,
    createStore,
    useStore,
};
