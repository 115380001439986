import {
    Button,
    Fab,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';


import {Options} from "../../event-log/components/table/ListPageService";
import React, {PropsWithChildren, useEffect, useState} from 'react';
import {useOperatorId} from "../../../hooks/useOperatorId";
import {ReviewService} from "./ReviewService";
import {RouterLink} from "../../../elements/RouterLink";
import {HumanDate} from "../../mixin/HumanDate";
import {ReviewsList} from '@electrifly/central-client-api';
import {DefaultApprovalStatus} from "@electrifly/central-client-api/data/enums/ModelEnums";
import {useIsMobile} from "../../../misc/Helpers";
import CheckIcon from '@mui/icons-material/Check';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ConfirmationDialog from "../../mixin/ConfirmationDialog";
import {useRegistry} from "../../../services/GlobalRegistry";
import {ChargeLocation, RoamingLocation} from "@electrifly/central-client-api";

interface ReviewTableItemProps {
    review: any
    showHideButton?: boolean
}

function ReviewTableItem({review, showHideButton}: ReviewTableItemProps) {
    const operatorId = useOperatorId()
    const toggleReviewStatus = ReviewService.useStore(store => store.toggleStatus);
    const toggleStatus = (status: DefaultApprovalStatus) => toggleReviewStatus(review._id, status)
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const isMobile = useIsMobile()
    const {roamingLocations, chargeLocations} = useRegistry(store => store)

    const location: ChargeLocation | RoamingLocation = roamingLocations[review.location] ?? chargeLocations[review.location]

    const generateUrl = () => {
        // When it`s still loading
        if(!location) {
            return ""
        }

        if(location.roamingXP) {
            return `/${operatorId}/roaminglocations/${review.location}`
        }

        return `/${operatorId}/locations/${review.location}/reviews`
    }

    const onDialogClose = (val: boolean) => {
        setShowConfirmDialog(false)

        if (val) {
            toggleStatus(DefaultApprovalStatus.DELETED)
        }
    }

    return (
        <TableRow key={review._id} hover={true}
                  sx={{'&:last-child td, &:last-child th': {border: 0}, textDecoration: 'none'}}
        >
            <TableCell width={'15%'}>
                <ConfirmationDialog message={"Вы уверены что хотите удалить этот отзыв?"}
                                    onClose={onDialogClose} open={showConfirmDialog}/>

                <HumanDate time={review.created}/>
                <br/>
                <br/>
                {
                    isMobile && <>
                        <RouterLink to={generateUrl()}>
                            Станция: {location?.name ?? 'Loading...'}
                        </RouterLink>
                        <br/>
                        <RouterLink to={`/${operatorId}/customers/${review.account}`}>
                            Клиент: {review.accountName}
                        </RouterLink>
                        <p style={{margin: 0}}>
                            Оценка: {review.stars}
                        </p>

                        {
                            !review.transaction ? 'Транзакция: отсутствует' : (
                                <RouterLink to={`/${operatorId}/transactions/${review.transaction}`}>
                                    Транзакция: {review.transaction}
                                </RouterLink>
                            )
                        }

                        <Grid
                            container
                            direction="row"
                            spacing={1}
                            sx={{
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginTop: 1,
                            }}
                        >
                            {
                                review.status != 'approved' && <Grid item>
                                    <Fab variant={"circular"} size={"small"} color={"success"}
                                         onClick={() => toggleStatus(DefaultApprovalStatus.APPROVED)}>
                                        <CheckIcon fontSize={"small"}/>
                                    </Fab>
                                </Grid>
                            }

                            {
                                review.status != 'hidden' && <Grid item>
                                    <Fab variant={"circular"} size={"small"} color={"inherit"}
                                         onClick={() => toggleStatus(DefaultApprovalStatus.HIDDEN)}>
                                        <VisibilityOffIcon fontSize={"small"}/>
                                    </Fab>
                                </Grid>
                            }

                            {
                                review.status != 'deleted' && <Grid item>
                                    <Fab variant={"circular"} size={"small"} color={"error"}
                                         onClick={() => setShowConfirmDialog(true)}
                                    >
                                        <DeleteOutlineIcon fontSize={"small"}/>
                                    </Fab>
                                </Grid>
                            }
                        </Grid>
                    </>
                }
            </TableCell>
            {
                !isMobile && <>
                    <TableCell width={'15%'}>
                        <RouterLink to={generateUrl()}>
                            {location?.name ?? 'Loading...'}
                        </RouterLink>
                        <br/>
                        <br/>
                        <RouterLink to={`/${operatorId}/customers/${review.account}`}>
                            {review.accountName}
                        </RouterLink>
                    </TableCell>
                    <TableCell>
                        {
                            !review.transaction ? 'Отсутствует' : (
                                <RouterLink to={`/${operatorId}/transactions/${review.transaction}`}>
                                    {review.transaction}
                                </RouterLink>
                            )
                        }
                    </TableCell>
                    <TableCell>
                        {review.stars}
                    </TableCell>
                </>
            }
            <TableCell width={isMobile ? '70%' : '15%'}>
                {review.message}
            </TableCell>
            {
                !isMobile && <TableCell>
                    <Grid
                        container
                        direction="column"
                        sx={{
                            justifyContent: "center",
                            alignItems: "flex-start",
                        }}
                    >
                        {
                            review.status != DefaultApprovalStatus.APPROVED &&
                            <Button onClick={() => toggleStatus(DefaultApprovalStatus.APPROVED)}
                                    variant="contained"
                                    color={'success'}>
                                Разрешить
                            </Button>
                        }
                        {
                            review.status != DefaultApprovalStatus.HIDDEN &&
                            <Button style={{marginTop: 15}}
                                    onClick={() => toggleStatus(DefaultApprovalStatus.HIDDEN)}
                                    variant="contained" color={'inherit'}>
                                Скрыть
                            </Button>
                        }
                        {
                            review.status != DefaultApprovalStatus.DELETED &&
                            <Button style={{marginTop: 15}}
                                    onClick={() => setShowConfirmDialog(true)}
                                    variant="contained" color={'error'}>
                                Удалить
                            </Button>
                        }
                    </Grid>
                </TableCell>
            }
        </TableRow>
    );
}

interface ChargeLocationTableProps {
    showHiddenButton?: boolean
}

function MorphPhotosTable({showHiddenButton}: ChargeLocationTableProps) {
    const reviews = ReviewService.useStore(store => store.reviews);
    const isMobile = useIsMobile()

    // const useLocations = useChargeLocation()

    // console.log(useLocations);

    // console.log(photos);

    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell width={isMobile ? '30%' : undefined}>{isMobile ? 'Информация' : 'Дата'}</TableCell>
                        {
                            !isMobile && <>
                                <TableCell size={'small'}>{showHiddenButton ? 'Клиент' : 'Адрес'}</TableCell>
                                <TableCell>Транзакция</TableCell>
                                <TableCell>Оценка</TableCell>
                            </>
                        }
                        <TableCell width={isMobile ? '30%' : undefined}>Сообщение</TableCell>
                        {
                            !isMobile && <TableCell>Действия</TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        reviews.map((review, key) => (
                            <ReviewTableItem showHideButton={showHiddenButton} review={review}
                                             key={key}></ReviewTableItem>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export interface Filter {
    operator?: string;
    chargeLocationId?: string;
    holder?: string;
}

interface Props {
    filter?: Filter;
}

interface ActionEventListTableProps {
    filter: ReviewsList.ReqQuery;
    options?: Partial<Options>;
}

function LoadWrapper({children, filter}: PropsWithChildren<{}> & ActionEventListTableProps) {
    const store = ReviewService.useStore(store => store);
    const {loadNext, reset} = store

    useEffect(() => {
        store.setFilter(filter)

        loadNext();
        return () => reset();
    }, [filter.status]);

    return <>{children}</>;
}

export default function ReviewsListTableComponent({filter = {}, options = {}}: ActionEventListTableProps) {
    // const filteredLocationIds = useFilteredLocationIds(filter);

    return <ReviewService.Provider createStore={() => ReviewService.createStore(filter, {})}>
        <LoadWrapper filter={filter}>
            <MorphPhotosTable/>
        </LoadWrapper>
    </ReviewService.Provider>
}
