import {
    ChargeLocation,
    ChargeLocationUpdate,
    ChargePoint,
    ChargePointUpdate,
    Connector,
    ConnectorUpdate,
    TransactionPrice,
} from '@electrifly/central-client-api';
import produce from 'immer';
import { API } from '../core/api-client';
import { createWithImmer } from '../misc/CreateWithImmer';
import { Registry, RegistryData } from './GlobalRegistry';
import { nanoid } from 'nanoid';
import { arrayMove } from '@dnd-kit/sortable';
import _ from 'lodash';

export type RegistryEditorData = {};

type RegistryEditorActions = {
    updateGarageAction: () => Promise<void>;

    updateChargeLocation: (location: ChargeLocation, data: ChargeLocationUpdate.ReqBody) => Promise<void>;
    updateChargePoint: (chargePoint: ChargePoint, data: ChargePointUpdate.ReqBody) => Promise<void>;
    updateConnector: (connector: Connector, data: ConnectorUpdate.ReqBody) => Promise<void>;
    updateConnectorPrice: (connector: Connector, tariffId: string, newPrice: TransactionPrice) => Promise<void>;
    reorderConnectorTariff: (
        connector: Connector,
        groupId: string,
        oldIndex: number,
        newIndex: number,
    ) => Promise<void>;
    removeAccountFromTariffGroup: (connector: Connector, groupId: string, account: string) => Promise<void>;
};

type RegistryEditorType = RegistryEditorData & RegistryEditorActions;

export const RegistryEditor = createWithImmer<RegistryEditorType>(set => {
    return {
        updateChargeLocation: async (location: ChargeLocation, data: ChargeLocationUpdate.ReqBody) => {
            Registry.setState(
                produce<RegistryData>(draft => {
                    if (!draft.chargeLocations[location._id]) {
                        return;
                    }
                    const locationDraft = draft.chargeLocations[location._id];

                    locationDraft.name = data.name || locationDraft.name;
                    locationDraft.address = data.address || locationDraft.address;
                    locationDraft.city = data.city ?? locationDraft.city;
                    locationDraft.region = data.region ?? locationDraft.region;
                    locationDraft.coordinates.latitude = data.latitude || locationDraft.coordinates.latitude;
                    locationDraft.coordinates.longitude = data.longitude || locationDraft.coordinates.longitude;
                    locationDraft.directions = data.directions ?? locationDraft.directions;
                    locationDraft.businessHours.twentyFourSeven =
                        data.twentyFourSeven || locationDraft.businessHours.twentyFourSeven;
                    locationDraft.businessHours.regular = data.regularHours || locationDraft.businessHours.regular;
                    locationDraft.parkingType = data.parkingType || locationDraft.parkingType;
                    locationDraft.parkingEVOnly = data.parkingEVOnly ?? locationDraft.parkingEVOnly;
                    locationDraft.parkingCustomersOnly =
                        data.parkingCustomersOnly ?? locationDraft.parkingCustomersOnly;
                    locationDraft.facilities = data.facilities || locationDraft.facilities;
                    locationDraft.amenities = data.amenities || locationDraft.amenities;
                    locationDraft.publish = data.publish ?? locationDraft.publish;
                    locationDraft.infoMessage = data.infoMessage ?? locationDraft.infoMessage;
                    locationDraft.maxKwh = data.maxKwh ?? locationDraft.maxKwh
                }),
            );

            const [error, res] = await API.chargeLocationUpdate(location._id, data);

            if (!error) {
                console.log(res.data);
                return;
            }

            console.log(error.response?.data);
        },

        updateChargePoint: async (chargePoint: ChargePoint, data: ChargePointUpdate.ReqBody) => {
            Registry.setState(
                produce<RegistryData>(draft => {
                    if (!draft.chargePoints[chargePoint._id]) {
                        return;
                    }
                    const chargePointDraft = draft.chargePoints[chargePoint._id];

                    chargePointDraft.physicalReference = data.physicalReference || chargePointDraft.physicalReference;
                    chargePointDraft.vendor = data.vendor || chargePointDraft.vendor;
                    chargePointDraft.model = data.model || chargePointDraft.model;
                    chargePointDraft.serialNumber = data.serialNumber || chargePointDraft.serialNumber;
                    chargePointDraft.simNumber = data.simNumber || chargePointDraft.simNumber;
                    chargePointDraft.publish = data.publish ?? chargePointDraft.publish;
                    chargePointDraft.freevend = data.freevend ?? chargePointDraft.freevend;
                    chargePointDraft.ignorePingPong = data.ignorePingPong ?? chargePointDraft.ignorePingPong;
                    chargePointDraft.comment = data.comment ?? chargePointDraft.comment;
                }),
            );

            const [error, res] = await API.chargePointUpdate(chargePoint._id, data);

            if (!error) {
                console.log(res.data);
                return;
            }

            console.log(error.response?.data);
        },

        async updateGarageAction () {
            const [error, res] = await API.updateGarageAction();

            console.log(error);
            console.log(res?.data);
        },

        updateConnectorPrice: async (connector: Connector, tariffId: string, newPrice: TransactionPrice) => {
            Registry.setState(
                produce<RegistryData>(draft => {
                    if (!draft.connectors[connector._id]) {
                        return;
                    }
                    const tariffIindex = draft.connectors[connector._id].tariffs.findIndex(
                        item => item._id === tariffId,
                    );
                    if (tariffIindex === -1) {
                        return;
                    }

                    draft.connectors[connector._id].tariffs[tariffIindex].price = newPrice;
                }),
            );

            const [error, res] = await API.connectorTariffUpdate(connector._id, { tariffId: tariffId, ...newPrice });

            if (!error) {
                console.log(res.data);
                return;
            }

            console.log(error.response?.data);
        },

        updateConnector: async (connector: Connector, data: ConnectorUpdate.ReqBody) => {
            Registry.setState(
                produce<RegistryData>(draft => {
                    const connectorDraft = draft.connectors[connector._id];
                    if (!connectorDraft) {
                        return;
                    }

                    connectorDraft.standard = data.standard || connectorDraft.standard;
                    connectorDraft.format = data.format || connectorDraft.format;
                    connectorDraft.powerType = data.powerType || connectorDraft.powerType;
                    connectorDraft.power = data.power ?? connectorDraft.power;
                }),
            );

            const [error, res] = await API.connectorUpdate(connector._id, data);

            if (!error) {
                console.log(res.data);
                return;
            }

            console.log(error.response?.data);
        },

        reorderConnectorTariff: async (connector: Connector, groupId: string, oldIndex: number, newIndex: number) => {
            Registry.setState(
                produce<RegistryData>(draft => {
                    if (!draft.connectors[connector._id]) {
                        return;
                    }
                    const tariffs = arrayMove(draft.connectors[connector._id].tariffs, oldIndex, newIndex);
                    draft.connectors[connector._id].tariffs = tariffs;
                }),
            );

            const [error, res] = await API.connectorTariffReorder(connector._id, {
                groupId,
                oldIndex,
                newIndex,
            });

            if (!error) {
                console.log(res.data);
                return;
            }

            console.log(error.response?.data);
        },

        removeAccountFromTariffGroup: async (connector: Connector, groupId: string, account: string) => {
            Registry.setState(
                produce<RegistryData>(draft => {
                    if (!draft.connectors[connector._id]) {
                        return;
                    }
                    const tariffIndex = draft.connectors[connector._id].tariffs.findIndex(
                        item => item.groupId === groupId,
                    );
                    if (tariffIndex === -1) {
                        throw new Error('Tariff not found by id');
                    }

                    draft.connectors[connector._id].tariffs[tariffIndex].accounts =
                        draft.connectors[connector._id].tariffs[tariffIndex].accounts || [];
                    _.remove(draft.connectors[connector._id].tariffs[tariffIndex].accounts, item => item === account);
                }),
            );

            const [error, res] = await API.connectorTariffAccountRemove(connector._id, { groupId, account });

            if (!error) {
                console.log(res.data);
                return;
            }

            console.log(error.response?.data);
        },
    };
});

export const useRegistryEditor = RegistryEditor;
