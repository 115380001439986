import { Box, Button, Stack, Tab, Tabs } from '@mui/material';

import { Title } from '../../elements/Title';
import {RouterLink} from "../../elements/RouterLink";
import React, { useState } from "react";
import {useRouteMatch} from "../../hooks/useRouteMatch";
import {Outlet, useParams } from 'react-router-dom';
import {useIsMobile} from "../../misc/Helpers";

export function MorphPhotosListTopMenu() {
    const { operatorId } = useParams();
    const routeMatch = useRouteMatch([`/${operatorId}/photos/`, `/${operatorId}/photos/approved`, `/${operatorId}/photos/hidden`, `/${operatorId}/photos/deleted`]);
    const currentTab = routeMatch?.pattern?.path;
    const isMobile = useIsMobile()

    return (
        <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTab} variant="scrollable" scrollButtons={isMobile} allowScrollButtonsMobile>
                <Tab label="Не обработанные" value={`/${operatorId}/photos/`} component={RouterLink} to="" />
                <Tab label="Опубликованные" value={`/${operatorId}/photos/approved`} component={RouterLink} to="approved" />
                <Tab label="Скрытые" value={`/${operatorId}/photos/hidden`} component={RouterLink} to="hidden" />
                {/*<Tab label="Удаленные" value={`/${operatorId}/photos/deleted`} component={RouterLink} to="deleted" />*/}
            </Tabs>
        </Box>
    );
}

export function MorphPhotosListLayout() {
    return (
        <>
            <Stack direction={'row'} sx={{ mb: 4 }}>
                <Title sx={{ flex: 1 }}>Фотографии</Title>
            </Stack>

            <MorphPhotosListTopMenu />
            <Outlet />
        </>
    );
}
