import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Typography } from '@mui/material';


export interface ConfirmationDialogRawProps {
    message: string
    open: boolean;
    onClose: (confirm: boolean) => void;
}

export default function ConfirmationDialog(props: ConfirmationDialogRawProps) {
    const { onClose, open, ...other } = props;
    const radioGroupRef = React.useRef<HTMLElement>(null);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose(false)
    };

    const handleOk = () => {
        onClose(true);
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            TransitionProps={{ onEntering: handleEntering }}
            open={open}
            {...other}
        >
            <DialogTitle>Подтвердите действие</DialogTitle>
            <DialogContent dividers>
                <Typography component={"p"}>{props.message}</Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel}>
                    Отменить
                </Button>
                <Button onClick={handleOk}>Подтвердить</Button>
            </DialogActions>
        </Dialog>
    );
}